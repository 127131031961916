import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible';
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {Toast} from 'vant';
import 'vant/lib/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'



Vue.config.productionTip = false;
Vue.use(Toast);
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.config.productionTip = false

// router.beforeEach((to, form, next) => {
//   window.document.title = to.meta.title == undefined ? '默认标题' : to.meta.title
//   next()
// })


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')