import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    //  {
    //     path: "/web",
    //     name: "web",
    //     redirect: "web",
    //     component: () => import("../views/Layout/index.vue"),
    //     children: [
    //         {
    //             path: "meoo",
    //             name: "meoo",
    //             meta: { title: 'Meoo Live' },
    //             component: () => import("../web/meoo.vue")
    //         },
    //     ]
    // }
    {
        path: "/",
        name: "web",
        meta: { title: 'Meoo Live' },
        component: () => import("../web/meoo.vue")
    }
]

const router = new VueRouter({
    routes
})

export default router;